<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="showLoading">
    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
      <b-row>
        <b-col lg="6" md="6" sm="6" xs="12">
          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
            <b-form-group
              label-for="org_id"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
              {{$t('organogram.org')}} <span class="text-danger">*</span>
            </template>
            <b-form-select
              plain
              v-model="form.org_id"
              :disabled="editMode"
              :options="orgList"
              id="org_id"
              :state="errors[0] ? false : (valid ? true : null)"
            >
              <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
            </b-form-group>
        </ValidationProvider>
        </b-col>
        <b-col lg="6" md="6" sm="6" xs="12">
          <ValidationProvider name="Designation" vid="role_id" rules="required|min_value:1">
            <b-form-group
              label-for="role_id"
              slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
              {{$t('userManagement.roles')}} <span class="text-danger">*</span>
            </template>
            <b-overlay :show="unitLoad">
            <b-form-select
              plain
              :disabled="editMode"
              v-model="form.role_id"
              :options="roleList"
              id="role_id"
              :state="errors[0] ? false : (valid ? true : null)"
            >
              <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-overlay>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
            </b-form-group>
        </ValidationProvider>
        </b-col>
        <b-col sm="6">
          <ValidationProvider name="Manual One" vid="pdf_file">
            <b-form-group
              label-for="pdf_file">
              <template v-slot:label>
                {{ $t('userManagement.manual_one') }}
              </template>
              <b-form-input
                id="pdf_file"
                v-model="form.pdf_file"
                :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col sm="6">
          <ValidationProvider name="Manual Two" vid="pdf_file_two">
            <b-form-group
              label-for="pdf_file_two">
              <template v-slot:label>
                {{ $t('userManagement.manual_two') }}
              </template>
              <b-form-input
                id="pdf_file_two"
                v-model="form.pdf_file_two"
                :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row class="text-right">
        <b-col>
            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
        </b-col>
      </b-row>
    </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { manualUploadStore, manualUploadEditList } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      editMode: false,
      showLoading: false,
      form: {
        pdf_file: '',
        pdf_file_two: '',
        org_id: 0,
        role_id: 0
      },
      unitLoad: false,
      roles: [],
      designationList: []
    }
  },
  created () {
    if (this.id) {
      this.editMode = true
      this.tmpData()
    }
  },
  watch: {
    'form.org_id': function (newVal, oldVal) {
      this.roles = newVal > 0 ? this.roleDataCome(newVal) : []
      this.tmpData()
    },
    'form.role_id': function (newVal, oldVal) {
      this.tmpData()
    }
  },
  computed: {
    roleList: function () {
      if (this.roles) {
        const listObject = this.roles
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.role_name_bn }
          } else {
              return { value: obj.id, text: obj.role_name }
          }
        })
        return tmpList
      } else {
        return []
      }
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.orgProfileList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  methods: {
    getDesignationList (orgId) {
      return this.$store.state.CommonService.commonObj.designationList.filter(item => item.status === 1 && item.org_id === orgId).map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    roleDataCome: function (orgId) {
      this.unitLoad = true
      RestApi.getData(authServiceBaseUrl, 'user-management/role/role-list-select/' + orgId, {}).then(response => {
        this.roles = response
        this.unitLoad = false
        this.showLoading = false
      }, error => {
        this.unitLoad = false
        this.showLoading = false
        if (error) {
          //
        }
      })
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      result = await RestApi.postData(authServiceBaseUrl, manualUploadStore, this.form)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    async tmpData () {
      if (this.form.org_id > 0 && this.form.role_id > 0) {
            this.showLoading = true
            const params = { org_id: this.form.org_id, role_id: this.form.role_id }
            const response = await RestApi.getData(authServiceBaseUrl, manualUploadEditList, params)
            if (response.success) {
              const tmp = response.data
              this.form.id = tmp.id
              this.form.org_id = tmp.org_id
              this.form.role_id = tmp.role_id
              this.form.pdf_file = tmp.pdf_file
              this.form.pdf_file_two = tmp.pdf_file_two
              this.showLoading = false
            } else {
              this.form.pdf_file = ''
              this.form.pdf_file_two = ''
              this.showLoading = false
            }
        } else {
            this.showLoading = true
          const x = this.id ? this.$store.state.list.find(item => item.id === this.id) : this.form
          if (x.org_id > 0 && x.role_id > 0) {
            const params = { org_id: x.org_id, role_id: x.role_id }
            const response = await RestApi.getData(authServiceBaseUrl, manualUploadEditList, params)
            if (response.success) {
              const tmp = response.data
              this.form.id = tmp.id
              this.form.org_id = tmp.org_id
              this.form.role_id = tmp.role_id
              this.form.pdf_file = tmp.pdf_file
              this.form.pdf_file_two = tmp.pdf_file_two
              this.showLoading = false
            } else {
              this.form.org_id = 0
              this.form.role_id = 0
              this.form.pdf_file = ''
              this.form.pdf_file_two = ''
              this.showLoading = false
            }
          }
        }
    }
  }
}
</script>
