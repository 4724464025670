<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('userManagement.manual') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
         <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Area Type" vid="org_id">
                    <b-form-group
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{$t('organogram.org')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgProfileList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Division" vid="role_id">
                    <b-form-group
                        label-for="role_id"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{$t('userManagement.roles')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="search.role_id"
                        :options="roleList"
                        id="role_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col sm="4" class="mt-4">
                <b-button size="sm" variant="primary" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
            </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('userManagement.manual') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-1 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial) }}
                  </template>
                  <template v-slot:cell(price_collection_date)="data">
                    {{ data.item.price_collection_date | dateFormat }}
                  </template>
                  <template v-slot:cell(market_directory_ids)="data">
                    {{ getMarketDirectoryList(data.item.market_directory_ids) }}
                  </template>
                  <template v-slot:cell(no_of_cases)="data">
                    {{ $n(data.item.no_of_cases, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(inspection_date)="data">
                    {{ data.item.inspection_date| dateFormat }}
                  </template>
                  <template v-slot:cell(pdf_file)="data">
                   <a v-if="data.item.pdf_file" :href="data.item.pdf_file" target="_blank"><span v-if="data.item.pdf_file">{{ data.item.pdf_file }}</span></a>
                  </template>
                  <template v-slot:cell(pdf_file_two)="data">
                  <a v-if="data.item.pdf_file_two" :href="data.item.pdf_file_two" target="_blank"><span v-if="data.item.pdf_file_two">{{ data.item.pdf_file_two }}</span></a>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-primary" v-if="data.item.status == 1">{{$t('globalTrans.draft')}}</span>
                    <span class="badge badge-info" v-else-if="data.item.status == 2">{{$t('globalTrans.submitted')}}</span>
                    <span class="badge badge-success" v-else-if="data.item.status == 3">{{$t('priceMonitoring.published')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <b-modal id="modal-1" size="xl" :title="$t('userManagement.manual')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import FormV from './Form'
import { manualUploadList, priceEntryTogle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      authServiceBaseUrl: authServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      testId: 0,
      search: {
        org_id: 0,
        role_id: 0
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
        { labels: 'globalTrans.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'userManagement.roles', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'userManagement.manual_one', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
        { labels: 'userManagement.manual_two', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5 },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 6 }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      wardList: [],
      marketList: [],
      roles: [],
      cityCorporationList: [],
      pauroshobaList: []
    }
  },
  created () {
  },
  mounted () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search = Object.assign({}, this.search, {
        area_type_id: this.addressCustomUser.area_type_id,
        city_corporation_id: this.addressCustomUser.city_corporation_id,
        pauroshoba_id: this.addressCustomUser.pauroshoba_id,
        division_id: this.addressCustomUser.division_id,
        district_id: this.addressCustomUser.district_id,
        upazila_id: this.addressCustomUser.upazila_id,
        market_id: this.addressCustomUser.market_id,
        areaTypeList: JSON.stringify(this.loggedUserPrivilege.area_type_id),
        cityCorporationList: JSON.stringify(this.loggedUserPrivilege.city_corporation_id),
        pauroshobaList: JSON.stringify(this.loggedUserPrivilege.pauroshoba_id),
        divisionList: JSON.stringify(this.loggedUserPrivilege.division_id),
        districtList: JSON.stringify(this.loggedUserPrivilege.district_id),
        upazilaList: JSON.stringify(this.loggedUserPrivilege.upazila_id),
        marketList: JSON.stringify(this.loggedUserPrivilege.market_id)
      })
    }
    if (this.$store.state.Auth.activeRoleId === 1 || this.loggedUserPrivilege.area_type_id) {
      this.loadData()
    } else {
      this.$store.dispatch('setList', [])
    }
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      this.roles = this.roleDataCome(newVal)
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.marketList = this.getAreaMarketList(newVal)
      } else {
        this.marketList = []
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
        this.marketList = this.getDivMarketList(newVal)
      } else {
        this.districtList = []
        this.marketList = []
      }
    },
    'search.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        this.marketList = this.getMarketDirectoryList(newVal)
        this.cityCorporationList = this.getcityCorporationList(newVal)
      } else {
        this.upazilaList = []
        this.marketList = []
        this.cityCorporationList = []
      }
    },
    'search.upazila_id': function (newVal, oldVal) {
        if (newVal) {
          this.unionList = this.getUnionList(newVal)
          this.pauroshobaList = this.getPauroshobaList(newVal)
          this.marketList = this.getUpaMarketList(newVal)
        } else {
          this.unionList = []
          this.pauroshobaList = []
          this.marketList = this.getDefaultMarketList(this.search)
        }
    },
    'search.commodity_group_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.search.commodity_name_id = 0
            this.commodityNameList = this.getCommodityNameList(newVal)
        } else {
            this.commodityNameList = []
        }
    },
    'search.city_corporation_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.marketList = this.getCityMarketList(newVal)
        } else {
          this.marketList = []
        }
    }
  },
  computed: {
    roleList: function () {
      if (this.roles) {
        const listObject = this.roles
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.role_name_bn }
          } else {
              return { value: obj.id, text: obj.role_name }
          }
        })
        return tmpList
      } else {
        return []
      }
    },
    orgProfileList () {
      return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1)
    },
    loggedUserPrivilege: function () {
      return this.$store.state.BazarMonitoringService.commonObj.loggedUserPrivilege
    },
    addressCustomUser: function () {
      return this.$store.state.BazarMonitoringService.commonObj.addressCustomUser
    },
    areaTypeList: function () {
      const list = this.$store.state.commonObj.areaTypeList
      return list.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
       if (this.$i18n.locale === 'bn') {
            keys = [
                { key: 'serial' },
                { key: 'org_name_bn' },
                { key: 'roles.role_name_bn' },
                { key: 'pdf_file' },
                { key: 'pdf_file_two' },
                { key: 'action' }
            ]
        } else {
            keys = [
                { key: 'serial' },
                { key: 'org_name' },
                { key: 'roles.role_name' },
                { key: 'pdf_file' },
                { key: 'pdf_file_two' },
                { key: 'action' }
            ]
        }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('orgProfile.org_profile') + ' ' + this.$t('globalTrans.entry') : this.$t('orgProfile.org_profile') + ' ' + this.$t('globalTrans.modify')
    }
  },
  methods: {
  roleDataCome: function (orgId) {
      this.unitLoad = true
      RestApi.getData(authServiceBaseUrl, 'user-management/role/role-list-select/' + orgId, {}).then(response => {
        this.roles = response
        this.unitLoad = false
        this.showLoading = false
      }, error => {
        this.unitLoad = false
        this.showLoading = false
        if (error) {
          //
        }
      })
    },
    resetId (item) {
      this.testId = 0
    },
    edit (item) {
      this.testId = item.id
    },
    pdfFileName (data) {
      if (data) {
        return data.split('/').pop()
      } else {
        return ''
      }
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(authServiceBaseUrl, priceEntryTogle, item)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(authServiceBaseUrl, manualUploadList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(org => org.value === parseInt(item.org_id))
        const designationObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
        const orgData = {}
        const designationData = {}
            if (typeof orgObj !== 'undefined') {
                orgData.org_name = orgObj.text_en
                orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            if (typeof designationObj !== 'undefined') {
              designationData.designation = designationObj.text_en
              designationData.designation_bn = designationObj.text_bn
            } else {
              designationData.designation = ''
              designationData.designation_bn = ''
            }
          return Object.assign({}, item, { serial: index + 1 }, orgData, designationData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpazilaList (id) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUnionList (upazilaId) {
      return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
    },
    getWardList (id) {
      return this.$store.state.CommonService.commonObj.wardList.filter(item => item.status === 1 && item.city_corporation_id === id)
    },
    getPauroshobaList (id) {
      return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getMarketDirectoryList (id) {
        return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.district_id === id)
    },
    getAreaMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.area_type_id === id)
    },
    getDivMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpaMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.upazila_id === id)
    },
    getCityMarketList (id) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.city_corporation_id === id)
    },
    getDefaultMarketList (data) {
      return this.$store.state.BazarMonitoringService.commonObj.marketDirectoryList.filter(item => item.status === 1 && item.area_type_id === data.area_type_id && item.division_id === data.division_id && item.district_id === data.district_id)
    },
    getcityCorporationList (id) {
      return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === id)
    },
    // remove (item) {
    //   this.changeStatus(3, authServiceBaseUrl, priceEntryTogle, item)
    // }
    remove (item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.statusChangeMsgPrice'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatus(3, authServiceBaseUrl, priceEntryTogle, item)
        }
      })
    }
  }
}
</script>

<style>
.btn-success {
  background-color: var(--success) !important;
  border-color: var(--success);
}
</style>
